html {
  font-size: 62.5%;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  background-color: #181a18;
  font-size: 2.5rem;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 55%;
  }
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 50%;
  }
}
