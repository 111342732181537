.inputContainer {
  width: 100%;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;

  input {
    padding: 1.5rem 1rem;
    font-size: 1.6rem;
    font-family: "Roboto", "sans-serif";
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    outline: none;
    transition: 0.2s all;

    &:focus {
      border-color: #6617cb;
    }
  }
}
