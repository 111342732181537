.textAreaContainer {
  width: 100%;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;

  label {
    color: #fff;
    margin-bottom: 2rem;
    font-size: 2rem;
  }

  textarea {
    padding: 1.5rem 1rem 10rem 1rem;
    font-size: 1.6rem;
    font-family: "Roboto", "sans-serif";
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    resize: vertical;
    outline: none;

    &:focus {
      border-color: #6617cb;
    }
  }
}
