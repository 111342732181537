.form {
  width: 40rem;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  border-radius: 0.75rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 768px) {
  .form {
    width: 100%;
    border-radius: 0;
  }
}
