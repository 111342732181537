.buttonContainer {
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: flex-end;

  button {
    width: 100%;
    padding: 1.5rem 6rem;
    border-radius: 5px;
    color: #fff;
    background: linear-gradient(to right, #6617cb, #bf3a30);
    font-size: 2.5rem;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.2s all;

    &:hover {
      color: #181a18;
    }
  }
}
