.contactPage {
  width: 90%;
  margin: 0 auto;
  color: #fff;
  background-color: #181a18;

  &-content {
    display: flex;
    justify-content: center;
  }

  &-infoContainer {
    padding: 0 4rem 4rem 0;

    &-description {
      margin-bottom: 4rem;
      letter-spacing: 0.05em;

      h1 {
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 2.4rem;
      }

      p {
        margin: 0;
        font-size: 1.6rem;
      }
    }

    &-personalInfo {
      font-size: 1.6rem;
      display: flex;
      flex-direction: column;

      & > div {
        display: flex;
        align-items: center;

        border: 1px solid #181a18;
        padding: 2rem;
        transition: 0.2s all;

        &:hover {
          border: 1px solid #6617cb;
          border-radius: 5px;
        }
      }

      .icon {
        width: 2.8rem;
        height: 2.8rem;
        margin-right: 2rem;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .contactPage {
    &-content {
      flex-direction: column;
      align-items: center;
    }
  }
}
