.loadingSpinner {
  margin: 0 auto;
  background-color: transparent;
  border-radius: 50%;
  border: 1.2rem solid white;
  border-bottom: 1.2rem solid #6617cb;
  animation-name: spinnerAnimation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes spinnerAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
